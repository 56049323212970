var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "view view-cart" },
    [
      _vm.productPage && _vm.product
        ? [
            _c("section", { staticClass: "section sectionCart" }, [
              _c(
                "div",
                { staticClass: "container container--xs" },
                [
                  _c("p", [_vm._v(_vm._s(_vm.product.name))]),
                  _c("img", {
                    staticClass: "productImage",
                    attrs: { src: _vm.product.image_url },
                  }),
                  _c("CartPaymentFormPaypalDirect"),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }